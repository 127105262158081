const viewerEncryption = async (requestBody) => {
    return new Promise( async (resolve, reject) => {
        try{
            const baseURL = "https://3vzo3s0o2i.execute-api.us-east-1.amazonaws.com/v1_0/viewer";
            const endpoint = "/nonce";
            const url = baseURL + endpoint;
            const res = await fetch(url, {
                method: 'POST',headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(requestBody)
            });
            if(!res.ok) {
                const message = await res.text();
                reject({ name: res.status, message: message });
            }
            const proof = await res.text();
            resolve(proof);
        } catch (err) {
            reject(err);
        }
    })
}

export default viewerEncryption;