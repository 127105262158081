import { CognitoUserPool } from "amazon-cognito-identity-js";
import UserAuth from "./authentication/UserAuth";
import OnChainDBManip from "./onchain_db/OnChainManip";
import EncryptionManip from "./encryption/EncryptionManip.js";

class N7SDK {
    constructor(){
        const cpViewerPoolData = {
            UserPoolId: "us-east-1_yfarGUd2Q",
            ClientId: "m4ce0278rg3lk1r5fue7ctqlp"
        }
        const cpViewerPool = new CognitoUserPool(cpViewerPoolData);

        this.CpViewerAuth = new UserAuth(cpViewerPool);
        this.OnChainDBManip = new OnChainDBManip();
        this.EncryptionManip = new EncryptionManip();
    }
}

export default N7SDK;