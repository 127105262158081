import token from "../authentication/userToken";

async function viewerNonceQuery(serviceProvider, views, viewerId){
    return new Promise( async (resolve, reject) => {
        try{
            const userToken = token();
            const baseURL = "https://gxjybsd5dl.execute-api.us-east-1.amazonaws.com/v1_0/sp";
            const endpoint = "/contentAccess/nonce/" + serviceProvider + "/" + views + "?viewerId=" + viewerId;
            const url = baseURL + endpoint;
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': userToken
                }
            })
            if(!res.ok){
                const message = await res.text();
                reject({ name: res.status, message: message });
            }
            resolve(res.text());
        } catch (err) {
            reject(err);
        }
    })
}

export default viewerNonceQuery;