import token from "../authentication/userToken";
import newStream from "../streamManip/newStream";

const viewershipVerification = (viewer, proof, asset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const userToken = token();
            const baseURL = "https://gxjybsd5dl.execute-api.us-east-1.amazonaws.com/v1_0/sp"
            const validationEndpoint = "/contentAccess/validNonce";
            const validationUrl = baseURL + validationEndpoint;
            const validationRes = await fetch(validationUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': userToken,
                    "X-SpProxy": "proxy"
                },
                body: JSON.stringify(viewer)
            })
            const proofUploadEndpoint = "/contentAccess/proof";
            const proofUploadUrl = baseURL + proofUploadEndpoint;
            const proofUploadRes = await fetch(proofUploadUrl, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json",
                    "Authorization": userToken,
                    "X-SpProxy": "proxy"
                },
                body: JSON.stringify(proof)
            })

            if(!validationRes.ok || !proofUploadRes.ok){
                const message = !validationRes.ok ? await validationRes.text() : await proofUploadRes.text();
                reject({ name: res.status, message: message });
            } else {
                newStream(asset).catch(err => {
                    reject({ name: err.status, message: err.statusText });
                })
            }
            resolve(null);
        } catch(error){
            reject(error);
        }
    })
}

export default viewershipVerification;