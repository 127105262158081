import token from "../authentication/userToken";

const viewerSubscription = async (cpManager, requestBody) => {
    return new Promise ( async (resolve, reject) => {
        try{
            const userToken = token();
            const baseURL = "https://vb8q4x5v3e.execute-api.us-east-1.amazonaws.com/v1_0/cp";
            const endpoint = "/contentAccess/viewer";
            const url = baseURL + endpoint;
            const res = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': userToken,
                    'X-CpManager': cpManager
                },
                body: JSON.stringify(requestBody)
            });
            if(!res.ok) {
                const message = await res.text();
                reject({ name: res.status, message: message });
            } 
            const viewer = await res.text();
            resolve(viewer);
        } catch (err) {
            reject(err);
        }
    })
}

export default viewerSubscription;