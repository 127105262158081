import token from "../authentication/userToken";

const viewerTokenQuery = async (cpManager, viewerInfo) => {
    return new Promise(async (resolve, reject) => {
        try{
            const userToken = token();
            const baseURL = "https://vb8q4x5v3e.execute-api.us-east-1.amazonaws.com/v1_0/cp";
            const viewerId = viewerInfo.viewerId;
            const cpCode = viewerInfo.cpCode;
            const suppliedContentId = viewerInfo.suppliedContentId;
            const endpoint="/contentAccess/" + cpCode + "/token/" + suppliedContentId + "?viewerId=" + viewerId;
            const url = baseURL + endpoint;
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': userToken,
                    'X-CpManager': cpManager
                }
            });
            if(!res.ok) {
                const message = await res.text();
                reject({ name: res.status, message: message });
            } 
            const tokenToViewer = await res.text();
            resolve(tokenToViewer);
        } catch (err) {
            reject(err);
        }
    })
}

export default viewerTokenQuery;