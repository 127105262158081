import token from "../authentication/userToken";

const viewerContentQuery = async (cpCode, contentId, cpManager) => {
    return new Promise( async (resolve, reject) => {
        try{
            const userToken = token();
            const baseURL = "https://vb8q4x5v3e.execute-api.us-east-1.amazonaws.com/v1_0/cp";
            const endpoint = "/contentAccess/" + cpCode + "/contents/" + contentId;
            const url = baseURL + endpoint;
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': userToken,
                    'X-CpManager': cpManager
                }
            });
            if(!res.ok) {
                const message = await res.text();
                reject({ name: res.status, message: message });
            }
            const content = await res.json();
            resolve(content);
        } catch (err) {
            reject(err);
        }
    })
}

export default viewerContentQuery;