import viewerSubscription from "./viewerSubscription";
import viewerContentQuery from "./viewerContentQuery";
import viewerTokenQuery from "./viewerTokenQuery";
import viewerNonceQuery from "./viewerNonceQuery";
import viewerEncryption from "./viewerEncyption";
import viewershipVerification from "./viewershipVerifaication";

class OnChainDBManip {

    constructor(){   
    }

    async subscription(cpManager, requestBody){
        return viewerSubscription(cpManager, requestBody);
    }

    async getContent(cpCode, contentId, cpManager){
        return viewerContentQuery(cpCode, contentId, cpManager);
    }

    async queryToken(cpManager, viewerInfo){
        return viewerTokenQuery(cpManager, viewerInfo);
    }

    async queryNonce(serviceProvider, views, viewerId){
        return viewerNonceQuery(serviceProvider, views, viewerId);
    }

    async userSign(requestBody){
        return viewerEncryption(requestBody);
    }

    async sendProof(viewer, proof, asset){
        return viewershipVerification(viewer, proof, asset);
    }
}

export default OnChainDBManip;