import viewerEncryption from "./viewerEncryption";

class EncryptionManip {
    constructor(){
    }

    async userSign(privateKeyString, nonce){
        return viewerEncryption(privateKeyString, nonce);
    }
}

export default EncryptionManip;